import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isEmpty } from 'dpl/shared/utils/object';
import { AUTH_PATHS } from 'dpl/shared/constants/urls';
import ErrorWrapper from 'dpl/form/components/ErrorWrapper';

export default function LoginForm({
  buttonClassName,
  formState,
  formErrors,
  handleFormFieldChange,
  hideForgotPassword,
  onPasswordResetClick,
  isFormSubmitting,
  didFormSubmissionFail,
  handleFormSubmission
}) {
  function handleForgotPasswordClick(e) {
    e.preventDefault();
    onPasswordResetClick();
  }

  return (
    /* eslint-disable jsx-a11y/tabindex-no-positive */
    <div className="LoginForm">
      <form
        onSubmit={e => {
          e.preventDefault();
          handleFormSubmission();
        }}
      >
        <div className="row mb6">
          <div className="col">
            <label htmlFor="email">
              <p className="dark-gray mb2">Email address</p>
              <ErrorWrapper
                errors={formErrors.email}
                isShown={didFormSubmissionFail}
              >
                <input
                  tabIndex="1"
                  name="email"
                  className="w-100 br1 ba b--light-gray ph4 pv2"
                  value={formState.email || ''}
                  onChange={handleFormFieldChange}
                  placeholder="jane@highpeaksfarm.com"
                />
              </ErrorWrapper>
            </label>
          </div>
        </div>

        <div className="mb8 relative">
          <label htmlFor="password">
            <p className="dark-gray mb2">Password</p>
            <ErrorWrapper
              errors={formErrors.password}
              isShown={didFormSubmissionFail}
            >
              <input
                tabIndex="2"
                name="password"
                type="password"
                autoCapitalize="none"
                className="w-100 br1 ba b--light-gray ph4 pv2"
                value={formState.password || ''}
                onChange={handleFormFieldChange}
                placeholder="6+ characters"
              />
            </ErrorWrapper>
          </label>
        </div>

        <div className="mb12">
          <button
            tabIndex="3"
            data-test-id="login_submit"
            type="submit"
            disabled={isFormSubmitting || !isEmpty(formErrors)}
            className={classnames(buttonClassName, {
              'button--loading': isFormSubmitting
            })}
          >
            Log in
          </button>
          {!hideForgotPassword && (
            <div className="mt3 tc">
              <a
                tabIndex="4"
                className="tc f2 link--primary blue"
                data-test-id="forgot_password_button"
                onClick={
                  onPasswordResetClick ? handleForgotPasswordClick : null
                }
                href={AUTH_PATHS.FORGOT_PASSWORD}
              >
                Forgot password?
              </a>
            </div>
          )}
        </div>
      </form>
    </div>
    /* eslint-enable jsx-a11y/tabindex-no-positive */
  );
}

LoginForm.propTypes = {
  formState: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object.isRequired,
  handleFormFieldChange: PropTypes.func.isRequired,
  hideForgotPassword: PropTypes.bool,
  buttonClassName: PropTypes.string,
  onPasswordResetClick: PropTypes.func,
  handleFormSubmission: PropTypes.func.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  didFormSubmissionFail: PropTypes.bool.isRequired
};

LoginForm.defaultProps = {
  buttonClassName: 'w-100 button button--primary',
  hideForgotPassword: false,
  onPasswordResetClick: null
};
